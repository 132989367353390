import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import Header from "../components/header";
import Card from "../components/card";
import Title from "../components/title";
import header from "./../assets/headers/bedrooms.png";
import bedroom from "./../assets/imgs/bedroom3.jpg";
import restauration from "./../assets/imgs/restauration.jpg";
import sanitary from "./../assets/imgs/sanitary.jpg";

const BedroomsScreen = () => {
  const { t } = useTranslation();

  return (
    <MainContainer>
      <Header picture={header} title={t("bedrooms.title")} />
      <Content>
        <Card isRight image={bedroom}>
          <Title title={t("bedrooms.bedroom.title")} />
          <Text>{t("bedrooms.bedroom.content1")} </Text>
          <Text>{t("bedrooms.bedroom.content2")} </Text>
          <Text>{t("bedrooms.bedroom.content3")} </Text>
          <Text>{t("bedrooms.bedroom.content4")} </Text>
          <Text>{t("bedrooms.bedroom.content5")} </Text>
          <Text>{t("bedrooms.bedroom.content6")} </Text>
        </Card>
        <Card image={sanitary}>
          <Title title={t("bedrooms.dorm.title")} />
          <Text>{t("bedrooms.dorm.content1")} </Text>
          <Text>{t("bedrooms.dorm.content2")} </Text>
        </Card>
        <Card image={restauration} isRight>
          <Title title={t("bedrooms.mainBedroom.title")} />
          <Text>{t("bedrooms.mainBedroom.content1")} </Text>
          <Text>{t("bedrooms.mainBedroom.content2")} </Text>
          <Text>{t("bedrooms.mainBedroom.content3")} </Text>
        </Card>
      </Content>
    </MainContainer>
  );
};

const Text = styled.div`
  margin-bottom: 10px;
`;
const Content = styled.div`
  display: flex;
  width: 70%;
  margin-top: 10px;
  flex-direction: column;
  @media only screen and (max-width: 768px) {
    /* For mobile phones: */
    width: 95%;
  }
`;
const MainContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-family: Helvetica New;
  font-weight: 200;
`;
export default BedroomsScreen;
