import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import MainWrapper from "../components/main-wrapper";
import header from "./../assets/headers/virtual.png";
import martigneVideo from "./../assets/video/martigne.mov";

const VirtualScreen = () => {
  const { t } = useTranslation();
  return (
    <MainWrapper header={header} title={t("virtual.title")} centered>
      <Content>
        <video id="video" autoPlay loop width="1000" height="500">
          <source src={martigneVideo} type="video/mp4" />
        </video>
      </Content>
    </MainWrapper>
  );
};

const Content = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
`;
export default VirtualScreen;
