import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import Header from "../components/header";
import Card from "../components/card";
import Title from "../components/title";
import header from "./../assets/headers/arrangements.png";
import arrangements1 from "./../assets/imgs/arrangements1.jpg";
import arrangements2 from "./../assets/imgs/arrangements2.jpg";
import seminar3 from "./../assets/imgs/seminar3.jpg";

const ArrangementsScreen = () => {
  const { t } = useTranslation();

  return (
    <MainContainer>
      <Header picture={header} title={t("arrangements.title")} />
      <Content>
        <Card isRight image={arrangements1}>
          <Title title={t("arrangements.first.title")} />
          <Text>{t("arrangements.first.1")} </Text>
          <Text>{t("arrangements.first.2")} </Text>
          <Text>{t("arrangements.first.3")} </Text>
          <Text>{t("arrangements.second.1")} </Text>
          <Text>{t("arrangements.second.2")} </Text>
          <Text>{t("arrangements.second.3")} </Text>
          <Text>{t("arrangements.second.4")} </Text>
        </Card>
        <Card image={seminar3} >
          <Title title={t("arrangements.third.title")} />
          <Text>{t("arrangements.third.1")} </Text>
          <Text>{t("arrangements.third.2")} </Text>
        </Card>
        <Card image={arrangements2} isRight>
          <Title title={t("arrangements.fourth.title")} />
          <Text>{t("arrangements.fourth.1")} </Text><br/>
          <Text>{t("arrangements.fourth.2")} </Text>
          <Text>{t("arrangements.fourth.3")} </Text>
          <Text>{t("arrangements.fourth.4")} </Text><br/>
          <Text>{t("arrangements.fourth.5")} </Text>
          <Text>{t("arrangements.fourth.6")} </Text>
          <Text>{t("arrangements.fourth.7")} </Text>
          <Text>{t("arrangements.fourth.8")} </Text>
          <Text>{t("arrangements.fourth.9")} </Text>
        </Card>
      </Content>
    </MainContainer>
  );
};

const Text = styled.div`
  margin-bottom: 10px;
`;
const Content = styled.div`
  display: flex;
  width: 70%;
  margin-top: 10px;
  flex-direction: column;
  @media only screen and (max-width: 768px) {
    /* For mobile phones: */
    width: 95%;
  }
`;
const MainContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-family: Helvetica New;
  font-weight: 200;
`;
export default ArrangementsScreen;
